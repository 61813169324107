<template>
  <div class="select-tree-box" style="position: relative">
    <Cascader
      :placeholder="placeholder"
      :search="search"
      ref="Cascader"
      :data="data1"
      :how="tjOrSw"
      :subjectNature="subjectNature"
      :isName="isName"
    />
    <div
      v-if="disable"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #f5f7fa;
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #c0c4cc;
        cursor: not-allowed;
        border: 1px solid #dcdfe6;
        opacity: 0.79;
      "
    ></div>
  </div>
</template>

<script>
import {
  GlIndustryLinkage,
  SwIndustryLinkage,
  GbIndustryLinkage,
} from "@/api.js";
import { industries_tree } from "@/api/database.js";
import * as sesstion from "@/store/localStorage";

export default {
  name: "cascaderBox",
  dec: "联级容器",
  props: [
    "search",
    "isGb",
    "isSw",
    "isActive",
    "defaultIndusCode",
    "defaultIndeusName",
    "disable",
    "placeholder",
    "default_val",
    "isName",
  ],
  components: {
    Cascader: require("./cascader").default,
  },
  data() {
    return {
      data1: [],
      tjOrSw: 5,
      industryData: {},
    };
  },
  created() {
    this.setData();
  },
  mounted() {},
  methods: {
    selectTreeShow() {
      this.$refs.Cascader.hide();
      // setTimeout(() => {
      //   this.$refs.Cascader.is_none = false;
      //   this.$refs.Cascader.is_select = false;
      // }, 200)
    },
    setOneData() {
      const data = [
        {
          expand: false,
          f_title: "工业",
          industry_code: "工业",
          select_option_active: false,
          title: "工业",
        },
      ];
      this.$refs.Cascader.data = data;
      this.$refs.Cascader.getIndustryCodeSelectList(
        "工业",
        [],
        this.$refs.Cascader.data,
        0
      );

      this.$refs.Cascader.initColor([0]);
    },
    setData() {
      if (this.isSw == "database") {
        industries_tree().then((res) => {
          this.databaseFilterData(res.data.results);
          this.filtersData(res.data.results);
          this.$refs.Cascader.data = res.data.results;

          this.$refs.Cascader.getIndustryCodeSelectList(
            "工业",
            [],
            this.$refs.Cascader.data,
            0
          );

          this.$refs.Cascader.initColor([0]);
        });
      } else if (this.isSw) {
        this.tjOrSw = 3;
        SwIndustryLinkage().then((res) => {
          this.filtersData(res.data.msg);
          this.$refs.Cascader.data = res.data.msg;
          this.setSwDefualtTitle();
        });
      } else {
        this.tjOrSw = 5;
        var arr = [
          "/CompanyDetail/FinancialReport",
          "/CompanyDetail/Whitewash",
          "/CompanyDetail/FinancialAnalysis",
          "/CompanyDetail/StandardAnalysis/listedCompany",
          "/CompanyDetail/CapitalCalculation/CalculationResults",
          "/CompanyDetail/CapitalCalculation/CalculationHigh",
          "/CompanyDetail/CapitalCalculation/DataEntry",
        ];
        var path = this.$route.path;
        // console.log(arr.includes(path), path);

        var parame = {
          exclude: arr.includes(path) ? undefined : 1,
        };
        if (this.isGb) {
          GbIndustryLinkage(parame).then((res) => {
            this.filtersData(res.data.msg);
            this.$refs.Cascader.data = res.data.msg;
            this.setGbDefualtTitle();
          });
        }
        // else {
        //   GlIndustryLinkage({
        //     ...parame,
        //     exclude: 0,
        //   }).then((res) => {
        //     this.filtersData(res.data.msg);
        //     this.$refs.Cascader.data = res.data.msg;
        //     this.setGlDefualtTitle();
        //   });
        // }
      }
    },
    databaseFilterData(data, f_title) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        item.f_title = f_title ? f_title + "/" + item.name : item.name;
        item.expand = false;
        item.title = item.name;
        item.industry_code = item.code;
        if (item.children.length)
          this.databaseFilterData(item.children, item.f_title);
        else delete item.children;
      }
    },
    filtersData(json) {
      for (let index = 0; index < json.length; index++) {
        const item = json[index];
        item.select_option_active = false;
        if (item.children) this.filtersData(item.children);
      }
    },
    setSwDefualtTitle() {
      var company = sesstion.getLocalData("company");
      var companyData = sesstion.getLocalData("companyData");

      if (this.default_val) {
        this.$refs.Cascader.getIndustryCodeSelectList(
          this.default_val,
          [],
          this.$refs.Cascader.data,
          0
        );

        this.$refs.Cascader.initColor([0]);
      } else if (company && this.$route.path == "/ListDebt") {
        var companyInfo = company.companyInfo || company;
        if (!this.isActive)
          this.$refs.Cascader.getIndustryCodeSelectList(
            companyInfo.SWAI_code,
            [],
            this.$refs.Cascader.data,
            0
          );
        this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
      } else {
        this.$refs.Cascader.getIndustryCodeSelectList(
          companyData.industry_code,
          [],
          this.$refs.Cascader.data,
          0
        );

        this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
      }
    },
    // 统计
    setGlDefualtTitle() {
      if (this.default_val) {
        this.$refs.Cascader.getIndustryCodeSelectList(
          this.default_val,
          [],
          this.$refs.Cascader.data,
          0
        );
        this.$refs.Cascader.initColor([0]);
        return false;
      }

      // const industryData = sesstion.getLocalData("industryData");
      var industry_code = "";
      var industry_name = "";

      if (this.defaultIndeusName) {
        industry_code = this.defaultIndusCode;
        industry_name = this.defaultIndeusName;
      } else if (!sessionStorage.industryData) {
      } else if (this.industryData.tjResult.industry_level_five) {
        industry_code =
          this.industryData.tjResult["industry_level_five"].industry_code;
        industry_name =
          this.industryData.tjResult["industry_level_five"].industry_name;
      } else if (this.industryData.tjResult.industry_level_four) {
        industry_code =
          this.industryData.tjResult["industry_level_four"].industry_code;
        industry_name =
          this.industryData.tjResult["industry_level_four"].industry_name;
      } else if (this.industryData.tjResult.industry_level_three) {
        industry_code =
          this.industryData.tjResult["industry_level_three"].industry_code;
        industry_name =
          this.industryData.tjResult["industry_level_three"].industry_name;
      } else if (this.industryData.tjResult.industry_level_two) {
        industry_code =
          this.industryData.tjResult["industry_level_two"].industry_code;
        industry_name =
          this.industryData.tjResult["industry_level_two"].industry_name;
      } else if (this.industryData.tjResult.industry_level_one) {
        industry_code =
          this.industryData.tjResult["industry_level_one"].industry_code;
        industry_name =
          this.industryData.tjResult["industry_level_one"].industry_name;
      }

      if (!this.isActive)
        this.$refs.Cascader.getIndustryCodeSelectList(
          industry_code,
          [],
          this.$refs.Cascader.data,
          0
        );
      this.$nextTick(() => {
        this.search();
      });
      // 如果映射为空值为空设置选择框默认值
      if (!this.get_industry_code()) {
        this.$refs.Cascader.initColor([0]);
      } else {
        // 否则直接传值
        this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
      }
      this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
    },
    // 国标
    setGbDefualtTitle() {
      if (this.default_val) {
        this.$refs.Cascader.getIndustryCodeSelectList(
          this.default_val,
          [],
          this.$refs.Cascader.data,
          0
        );
        this.$refs.Cascader.initColor([0]);
        return false;
      }
      // const industryData = sesstion.getLocalData("industryData");
      var industry_code = "";
      var industry_name = "";

      if (this.defaultIndeusName) {
        industry_code = this.defaultIndusCode;
        industry_name = this.defaultIndeusName;
      } else if (!sessionStorage.industryData) {
      } else if (this.industryData.gbResult.industry_level_five) {
        industry_code =
          this.industryData.gbResult["industry_level_five"].industry_code;
        industry_name =
          this.industryData.gbResult["industry_level_five"].industry_name;
      } else if (this.industryData.gbResult.industry_level_four) {
        industry_code =
          this.industryData.gbResult["industry_level_four"].industry_code;
        industry_name =
          this.industryData.gbResult["industry_level_four"].industry_name;
      } else if (this.industryData.gbResult.industry_level_three) {
        industry_code =
          this.industryData.gbResult["industry_level_three"].industry_code;
        industry_name =
          this.industryData.gbResult["industry_level_three"].industry_name;
      } else if (this.industryData.gbResult.industry_level_two) {
        industry_code =
          this.industryData.gbResult["industry_level_two"].industry_code;
        industry_name =
          this.industryData.gbResult["industry_level_two"].industry_name;
      } else if (this.industryData.gbResult.industry_level_one) {
        industry_code =
          this.industryData.gbResult["industry_level_one"].industry_code;
        industry_name =
          this.industryData.gbResult["industry_level_one"].industry_name;
      }

      if (!this.isActive)
        this.$refs.Cascader.getIndustryCodeSelectList(
          industry_code,
          [],
          this.$refs.Cascader.data,
          0
        );
      this.$nextTick(() => {
        this.search();
      });
      // 如果映射为空值为空设置选择框默认值
      if (!this.get_industry_code()) {
        this.$refs.Cascader.initColor([0]);
      } else {
        // 否则直接传值
        this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
      }
      this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
    },
    // 国标
    // setGbDefualtTitle() {
    //   const industryData = sesstion.getLocalData("industryData");
    //   console.log(industryData);
    //   var industry_code = "";
    //   var industry_name = "";

    //   if (this.defaultIndeusName !== undefined) {
    //     industry_code = this.defaultIndusCode;
    //     industry_name = this.defaultIndeusName;
    //   }
    //   else if (!sessionStorage.industryData) {
    //   }
    //   else if (industryData.gbResult.industry_level_five) {
    //     industry_code = industryData.gbResult["industry_level_five"].industry_code;
    //     industry_name = industryData.gbResult["industry_level_five"].industry_name;
    //   } else if (industryData.gbResult.industry_level_four) {
    //     industry_code = industryData.gbResult["industry_level_four"].industry_code;
    //     industry_name = industryData.gbResult["industry_level_four"].industry_name;
    //   } else if (industryData.gbResult.industry_level_three) {
    //     industry_code = industryData.gbResult["industry_level_three"].industry_code;
    //     industry_name = industryData.gbResult["industry_level_three"].industry_name;
    //   } else if (industryData.gbResult.industry_level_two) {
    //     industry_code = industryData.gbResult["industry_level_two"].industry_code;
    //     industry_name = industryData.gbResult["industry_level_two"].industry_name;
    //   } else if (industryData.gbResult.industry_level_one) {
    //     industry_code = industryData.gbResult["industry_level_one"].industry_code;
    //     industry_name = industryData.gbResult["industry_level_one"].industry_name;
    //   }

    //   if (!this.isActive)
    //     this.$refs.Cascader.getGbCodeSelectList(
    //       industry_code,
    //       [],
    //       this.$refs.Cascader.data,
    //       0
    //     );
    //   this.$nextTick(() => {
    //     this.search();
    //   });
    //    // 如果映射为空值为空设置选择框默认值
    //   if (!this.get_industry_code()) {
    //     this.$refs.Cascader.initColor([0]);
    //   } else {
    //      this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
    //   }
    //   // 覆盖默认值
    //   this.$refs.Cascader.initColor(this.$refs.Cascader.activeList);
    // },
    clearSelect() {
      this.$refs.Cascader.clearSelect();
    },
    get_gb_code() {
      return this.$refs.Cascader.get_gb_code();
    },
    get_industry_code() {
      return this.$refs.Cascader.get_industry_code();
    },
    get_f_title() {
      return this.$refs.Cascader.get_f_title();
    },
  },
};
</script>
<style lang="less" scoped>
.select-tree-box {
  width: 100%;
  .ivu-input {
  }
  position: relative;
  .tree-box {
    position: absolute;
    top: 30px;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    height: 200px;
    background: #fff;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    margin: 5px 0;
    padding: 5px 0;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 900;
  }
  .tree-title {
    padding-left: 8px;
    font-size: 12px;
    color: #999;
    height: 30px;
    line-height: 30px;
  }
}
</style>

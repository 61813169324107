<template>
  <div
    :style="
      $route.path == '/ListDebt'
        ? { width: '100%', height: '40px' }
        : { width: '180px' }
    "
  >
    <div class="select_tree_box">
      <div
        :class="
          $route.path == '/ListDebt'
            ? 'select_tree_title'
            : 'select_tree_title1'
        "
        :style="is_select && { 'border-color': '#57a3f3' }"
        @click="titleClick"
      >
        <div class="select_name" :title="select_name">
          {{ select_name }}
          <span
            v-if="!select_name"
            style="color: #c0c4cc; font-size: 13px; margin-left: 4px"
            >{{ placeholder }}</span
          >
        </div>
        <div
          class="ivu-icon ivu-icon-ios-arrow-down"
          :class="is_select && 'ivu-icon-rotate'"
          style="color: #808695; font-size: 14px"
        ></div>
      </div>
      <div
        class="tree_box"
        v-if="is_none"
        :class="[is_select && 'tree_bo_transition']"
      >
        <div class="tree_all_row">
          <div
            v-for="(item, index) in howLenght"
            :key="index"
            v-show="fiveState[index].show"
          >
            <div class="select_group_title">
              {{ titleArr[index] + "级选择" }}
            </div>
            <scrollBar>
              <div
                class="select_option"
                v-for="(itemClass, indexClass) in fiveState[index].data"
                :key="indexClass"
                :title="itemClass.title"
                :class="
                  itemClass.select_option_active && 'select_option_active'
                "
                @click="
                  selectItem(
                    $event,
                    fiveState[index],
                    itemClass,
                    index,
                    indexClass
                  )
                "
                @mouseenter="
                  showOrHideChildren(
                    $event,
                    indexClass,
                    index,
                    itemClass.children,
                    fiveState[index],
                    itemClass.select_option_active
                  )
                "
              >
                {{ itemClass.title }}
                <div
                  v-show="itemClass.children"
                  class="ivu-icon ivu-icon-ios-arrow-down"
                ></div>
              </div>
            </scrollBar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "cascader",
  dec: "联级选择器",

  props: {
    how: Number,
    search: Function,
    placeholder: {
      type: String,
      default: "请选择",
    },
    subjectNature: String,
    isName: String,
  },
  components: {
    scrollBar: require("@/components/scrollBar/scrollBar").default,
  },
  data() {
    return {
      select_name: "",
      is_select: false,
      is_none: false,
      timer: null,
      industry_code: "",
      gb_code: "",
      f_title: "",
      data: [],
      titleArr: ["一", "二", "三", "四", "五"],
      fiveState: [
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
      ],

      activeList: [],
    };
  },
  computed: {
    howLenght() {
      return ([].length = this.how);
    },
  },
  created() {
    // window.hideSelectTree && window.hideSelectTree.push(this);
    window.hideSelectTree[this.isName] = this;
    // this.initColor([0, 1]);
  },
  methods: {
    initColor(selectArr) {
      const fiveState = [
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
      ];

      var jsonData = JSON.parse(JSON.stringify(this.data));

      if (selectArr.length == 0) {
        fiveState[0].data = jsonData;
        fiveState[0].show = true;
        this.fiveState = fiveState;
        return false;
      }
      var lastIndex = selectArr.length - 1;
      for (let i = 0; i < selectArr.length; i++) {
        const indexClass = selectArr[i];
        fiveState[i].show = true;
        fiveState[i].data = jsonData;

        jsonData[indexClass].select_option_active = true;
        if (lastIndex == i) {
          this.select_name = jsonData[indexClass].f_title;
          this.f_title = jsonData[indexClass].f_title;
          this.industry_code = jsonData[indexClass].industry_code;
          this.gb_code = jsonData[indexClass].gb_code;
        }
        jsonData = jsonData[indexClass].children;
      }
      this.fiveState = fiveState;
    },
    titleClick() {
      if (this.timer) clearTimeout(this.timer);
      if (this.is_select == false) {
        this.is_none = true;
      } else {
        this.timer = setTimeout(() => {
          this.is_none = false;
        }, 500);
      }
      setTimeout(() => {
        this.$nextTick(() => {
          this.is_select = !this.is_select;
        });
      }, 200);
    },
    hide() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.is_none = false;
      }, 500);
      setTimeout(() => {
        this.$nextTick(() => {
          this.is_select = false;
        });
      }, 200);
    },
    showOrHideChildren(
      $event,
      indexClass,
      index,
      nextClass,
      fiveItem,
      select_option_active
    ) {
      if (nextClass) this.fiveState[index + 1].show = true;
      else this.fiveState[index + 1].show = false;

      var childrenI = index + 1 + 1;
      if (this.fiveState[childrenI]) {
        for (let i = childrenI; i < this.fiveState.length; i++) {
          const item = this.fiveState[i];
          item.show = false;
        }
      }

      this.fiveState[index + 1].data = nextClass;
    },
    selectItem($event, item, itemClass, indexClass, index) {
      var selectList = [],
        index = 0;

      if (itemClass.gb_code) {
        this.getGbCodeSelectList(
          itemClass.gb_code,
          selectList,
          this.data,
          index
        );
      } else {
        this.getIndustryCodeSelectList(
          itemClass.industry_code,
          selectList,
          this.data,
          index
        );
      }

      this.initColor(this.activeList);
      this.titleClick();
      this.$nextTick(() => {
        this.search();
      });
    },
    getIndustryCodeSelectList(industryCode, list, modulesList, index) {
      for (let i = 0; i < modulesList.length; i++) {
        const item = modulesList[i];
        list[index] = i;
        if (item.industry_code == industryCode) {
          this.activeList = list.slice(0, index + 1);
        }

        if (item.children) {
          var childrenIndex = index;
          childrenIndex++;
          var childrenI = this.getIndustryCodeSelectList(
            industryCode,
            list,
            item.children,
            childrenIndex
          );
          if (typeof childrenI == "number") break;
        }
      }
    },
    getGbCodeSelectList(gbCode, list, modulesList, index) {
      for (let i = 0; i < modulesList.length; i++) {
        const item = modulesList[i];
        list[index] = i;
        if (item.gb_code == gbCode) {
          this.activeList = list.slice(0, index + 1);
        }

        if (item.children) {
          var childrenIndex = index;
          childrenIndex++;
          var childrenI = this.getGbCodeSelectList(
            gbCode,
            list,
            item.children,
            childrenIndex
          );
          if (typeof childrenI == "number") break;
        }
      }
    },
    get_industry_code() {
      return this.industry_code;
    },
    get_gb_code() {
      return this.gb_code;
    },
    get_f_title() {
      return this.f_title;
    },
    clearSelect() {
      this.industry_code = "";
      this.f_title = "";
      this.select_name = "";
      this.gb_code = "";
    },
  },
  watch: {
    subjectNature(val, old) {
      if (val == "事业单位") {
        this.fiveState = [
          {
            show: false,
            data: [
              {
                expand: false,
                f_title: "医院",
                industry_code: "yy",
                select_option_active: false,
                title: "医院",
              },
              {
                expand: false,
                f_title: "学校",
                industry_code: "xx",
                select_option_active: false,
                title: "学校",
              },
              {
                expand: false,
                f_title: "其他",
                industry_code: "qt",
                select_option_active: false,
                title: "其他",
              },
            ],
          },
        ];
        this.placeholder = "请选择企业所在的行业";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.select_tree_box {
  position: relative;
  width: 100%;
  // min-height: 32px;
  .select_tree_title {
    position: relative;

    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdee2;
    padding: 2px 11px;
    padding-right: 21px;
    font-size: 16px;
    color: #333;
    line-height: 35px;
    height: 40px;
    cursor: pointer;

    transition: all 1s;
    .select_name {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 40px;
    }
    .ivu-icon {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 10px;
      font-size: 16px;
      color: rgb(192, 196, 204);
      transition: all 0.2s ease-in-out;
    }
    .ivu-icon-rotate {
      transform: rotate(180deg);
    }
  }
  .select_tree_title1 {
    position: relative;

    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdee2;
    padding: 2px 11px;
    padding-right: 21px;
    font-size: 12px;
    line-height: 24px;
    height: 32px;
    cursor: pointer;

    transition: all 1s;
    .select_name {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 26px;
    }
    .ivu-icon {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 10px;
      font-size: 16px;
      color: rgb(192, 196, 204);
      transition: all 0.2s ease-in-out;
    }
    .ivu-icon-rotate {
      transform: rotate(180deg);
    }
  }
  .select_title {
    border-color: #57a3f3;
    // box-shadow: 0 0 4px #494b4e;
  }
  .select_tree_title:hover {
    border-color: #57a3f3;
  }
  .none {
    display: none;
  }
  .tree_box {
    min-width: 133px;
    height: 100px;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transform: translate(-4px, 4px);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    z-index: 12;
    > .tree_all_row {
      display: flex;
      background: #fff;
      //   width: 100%;
      height: 100%;
      min-width: 133px;
      > div > div {
        width: 133px;

        // overflow-y: auto;
      }
      .select_group_title {
        padding: 8px 10px;
        border-right: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;

        background: #fff;
      }
      .scroll_bar {
        height: 194px;
        border-left: 1px solid #f4f4f4;
      }
    }
  }
  .tree_bo_transition {
    height: 235px;
    opacity: 1;
    // display: block;
  }
  .select_option {
    padding: 8px 20px 8px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    .ivu-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -8px;
      transform: rotate(-90deg);
    }
  }
  .select_option:hover {
    color: #1989fa;
  }
  .select_option_active {
    color: #1270ff;
  }
}
</style>

<template>
  <div class="scroll_bar" :class="'scroll_bar_main'" @mouseenter="fn" @mouseleave="fn">
    <slot></slot>
  </div>
</template>
<script>
export default {
    name: 'scrollBar',
    dec: '滚动条样式',
    data() {
        return {
            barShow: false,
            
        }
    },
    methods: {
        fn () {
            this.barShow = !this.barShow 
        }
    },
}
</script>
<style scoped>
.scroll_bar {
  width: 50px;
  height: 100px;
  overflow: hidden;
 
}
.scroll_bar_main{
     overflow-y: auto;
}
/* 谷歌  -webkit-*/
.scroll_bar_main::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scroll_bar_main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;

 background: #ededed;
}
.scroll_bar_main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/

  border-radius: 10px;
 
}
/*  Firefox 的 -moz-*/
.scroll_bar_main::-moz-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scroll_bar_main::-moz-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;

 background: #ededed;
}
.scroll_bar_main::-moz-scrollbar-track {
  /*滚动条里面轨道*/

  border-radius: 10px;
 
}
/* IE 的 -ms-*/
.scroll_bar_main::-ms-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scroll_bar_main::-ms-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;

 
}
.scroll_bar_main::-ms-scrollbar-track {
  /*滚动条里面轨道*/

  border-radius: 10px;
 
}
/* Opera 的 -o-*/
.scroll_bar_main::-o-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scroll_bar_main::-o-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;

 background: #ededed;
}
.scroll_bar_main::-o-scrollbar-track {
  /*滚动条里面轨道*/

  border-radius: 10px;
 
}
</style>
import service from "@/common/request.js";

/**
 * 获取数据库行业
 *

 */
export const industries = () => service.get("/data/ec/industries/");

/**
 * 获取数据库行业
 *
查询参数：

    industry    行业名称
    start_date   开始日期
    end_date   结束日期
 */
export const industries_values = (params) =>
  service.get("/data/ec/indicators/values/", { params });

/**
 *  默认数据
 *
 */
export const indicators = () => service.get("/data/ec/indicators/");

// 行业列表
export const industries_tree = () => service.get("/data/ec/industries_tree/");




/**
 * 企业绩效评价行业值
查询参数(都是必填)
    industry_code
    industry_range
    year
    period
 */

export const indicators_values = (params) =>
  service.get("/data/risk/indicators/values/", { params });



/**
* 企业绩效评价行业值------重点指标
  {
    "region": "天津市",
    "industry_code": "",
    "show_type": "0",0金额1增长率
    "start_date": "2022-10-01 00:00:00",
    "end_date": "2023-03-01 00:00:00"
}
*/
export const importance = (params) =>
  service.post("/data/ec/indicators/values/importance/", params);


export const interval_time = (params) =>
  service.get("/data/ec/indicators/values/time/interval/", params);


export const indicators_search = (params) =>
  service.post("/data/ec/indicators/values/search/", params);


export const indicators_detail = (params) =>
  service.post("/data/ec/indicators/values/detail/", params);


